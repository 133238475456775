(function ($) {
    function recaptchaUpdateToken() {
        grecaptcha.ready(function() {
        // do request for recaptcha token
        // response is promise with passed token
            grecaptcha.execute('6LckBNEdAAAAAJiz6WWBZLLp9xi1neER9vrYeOmr', {action:'validate_captcha'})
                    .then(function(token) {
                // add token value to form
                document.getElementById('g-recaptcha-response').value = token;
            });
        });
    }

    recaptchaUpdateToken();

    $('.header__menu, .header__burger-box').on('click', function() {
        $('.header__menu, .header__burger').toggleClass('active');
        $('body').toggleClass('lock');
    })

    $(".header__menu-item, .footer__menu-item").on('click', function() { // ID откуда кливаем
        $('html, body').animate({
            scrollTop: $($(this).attr("href")).offset().top  // класс объекта к которому приезжаем
        }, 1000); // Скорость прокрутки
    });

    $(".modal__close").on('click', function() {
        $('.modal').removeClass('active').addClass('hidden');
        $('body').css('overflow', 'initial');
    })

    $(".open-modal").on('click', function() {
        $('.modal').removeClass('hidden').addClass('active');
        $('body').css('overflow', 'hidden');
    })

    $(document).on('submit', '#sendForm', function(e) {
        e.preventDefault();

        let name  = $(this).find('[name="name"]');
        let phone = $(this).find('[name="phone"]');
        let grecaptcha = $(this).find('#g-recaptcha-response');
        let formSpinner = $('.spinner');
        let formButton = $(this).find('.modal__form-button .btn');
        let modalBody = $('.modal__body');
        let modalTitle = $('.modal__title');
        let formValidateBox = $(this).find('.modal__form-validate');

        formSpinner.removeClass('d-none');
        formButton.addClass('d-none');
        formValidateBox.html('');

        $.post('/send/send.php',{
            name: name.val(),
            phone: phone.val(),
            "g-recaptcha-response": grecaptcha.val()
        }).then((response) => {
            formSpinner.addClass('d-none');
            formButton.removeClass('d-none');

            let res = JSON.parse(response);

            if (res.status == 'error') {
                formValidateBox.html(res.message);
                return false;
            }

            if (res.status == 'success') {
                modalBody.html('<p class="success">'+res.message+'</p>');
                return false;
            }
        })
        .catch((err)=> {
            recaptchaUpdateToken();
            formSpinner.addClass('d-none');
            formButton.removeClass('d-none');
        })
    })
})(jQuery);